
  var editorScriptEntry = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      const { editorScriptBuilder } = require('@wix/bob-widget-services');
      const { editorReadyWrapper, createFlowAPIFabric, exportsWrapper } = require('@wix/yoshi-flow-editor/runtime/esm/editorScript.js');

      const biLogger = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/target/generated/bi/createBILogger.ts').createOwnerBILogger;

      
var initI18n = null;

      

    var createExperiments = null;
    var createWidgetExperiments = null;
    

      
const { createHttpClient } = require('@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp');
    


      const sentryConfig = {
        DSN: 'https://2062d0a4929b45348643784b5cb39c36@sentry.wixpress.com/1643',
        id: '2062d0a4929b45348643784b5cb39c36',
        projectName: 'communities-blog-ooi',
        teamName: 'blogas',
        errorMonitor: true,
      };
      const experimentsConfig = {"centralized":true,"scopes":["wix-social-blog","ricos"]};
      const translationsConfig = {"enabled":false,"icuEnabled":false};
      const defaultTranslations = null;

      const createFlowAPI = createFlowAPIFabric({
        sentryConfig,
        experimentsConfig,
        translationsConfig,
        defaultTranslations,
        biLogger,
        shouldUseEssentials: true,
        artifactId: 'communities-blog-ooi',
        appDefId: '14bcded7-0066-7c35-14d7-466cb3f09103',
        optionalDeps: {
          initI18n,
          createExperiments,
          createHttpClient
        },
        localeDistPath: 'assets/locales',
      });

      const useAPIsFromExports = typeof editorScriptEntry.exports === "function";

      function wrapEditorReady(eReady) {
        try {
          return editorReadyWrapper({
            createFlowAPI,
            editorReady: eReady,
            shouldSkipAPIOverrides: useAPIsFromExports,
          });
        } catch (e) {
          console.error(e);
          throw e;
        }
      }

      let wrappedEditorReady = wrapEditorReady(editorScriptEntry.editorReady);
      if (false) {
        const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
        wrappedEditorReady = hot(module, wrappedEditorReady);
      }

      let builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(wrappedEditorReady);
      }

      if (editorScriptEntry.exports) {
        builder = builder.withExports(useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      const controllerWidgetManifests = [];
      
      const userController_0 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Categories/editor.controller.ts');

      const model_0 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Categories/model.ts').default;

      const manifest_0 = userController_0 ? userController_0.default || userController_0 : {};
      if (!manifest_0.type) {
        manifest_0.type = "14bcded7-0066-7c35-14d7-466cb3f09103-pit6d";
      }
      if (!manifest_0.getExports && model_0) {
        manifest_0.getExports = () => ({"14bcded7-0066-7c35-14d7-466cb3f09103-pit6d": model_0.getExports()});
      }
      if (userController_0 && userController_0.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "14bcded7-0066-7c35-14d7-466cb3f09103-pit6d", getWidgetManifest: userController_0.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_0);
  
      const userController_1 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Counters/editor.controller.ts');

      const model_1 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Counters/model.ts').default;

      const manifest_1 = userController_1 ? userController_1.default || userController_1 : {};
      if (!manifest_1.type) {
        manifest_1.type = "14bcded7-0066-7c35-14d7-466cb3f09103-u61rq";
      }
      if (!manifest_1.getExports && model_1) {
        manifest_1.getExports = () => ({"14bcded7-0066-7c35-14d7-466cb3f09103-u61rq": model_1.getExports()});
      }
      if (userController_1 && userController_1.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "14bcded7-0066-7c35-14d7-466cb3f09103-u61rq", getWidgetManifest: userController_1.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_1);
  
      const userController_2 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Counters New/editor.controller.ts');

      const model_2 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Counters New/model.ts').default;

      const manifest_2 = userController_2 ? userController_2.default || userController_2 : {};
      if (!manifest_2.type) {
        manifest_2.type = "14bcded7-0066-7c35-14d7-466cb3f09103-pzdqd";
      }
      if (!manifest_2.getExports && model_2) {
        manifest_2.getExports = () => ({"14bcded7-0066-7c35-14d7-466cb3f09103-pzdqd": model_2.getExports()});
      }
      if (userController_2 && userController_2.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "14bcded7-0066-7c35-14d7-466cb3f09103-pzdqd", getWidgetManifest: userController_2.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_2);
  
      const userController_3 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Divider/editor.controller.ts');

      const model_3 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Divider/model.ts').default;

      const manifest_3 = userController_3 ? userController_3.default || userController_3 : {};
      if (!manifest_3.type) {
        manifest_3.type = "14bcded7-0066-7c35-14d7-466cb3f09103-yrjyo";
      }
      if (!manifest_3.getExports && model_3) {
        manifest_3.getExports = () => ({"14bcded7-0066-7c35-14d7-466cb3f09103-yrjyo": model_3.getExports()});
      }
      if (userController_3 && userController_3.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "14bcded7-0066-7c35-14d7-466cb3f09103-yrjyo", getWidgetManifest: userController_3.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_3);
  
      const userController_4 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Footer/editor.controller.ts');

      const model_4 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Footer/model.ts').default;

      const manifest_4 = userController_4 ? userController_4.default || userController_4 : {};
      if (!manifest_4.type) {
        manifest_4.type = "14bcded7-0066-7c35-14d7-466cb3f09103-h77jn";
      }
      if (!manifest_4.getExports && model_4) {
        manifest_4.getExports = () => ({"14bcded7-0066-7c35-14d7-466cb3f09103-h77jn": model_4.getExports()});
      }
      if (userController_4 && userController_4.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "14bcded7-0066-7c35-14d7-466cb3f09103-h77jn", getWidgetManifest: userController_4.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_4);
  
      const userController_5 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Header/editor.controller.ts');

      const model_5 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Header/model.ts').default;

      const manifest_5 = userController_5 ? userController_5.default || userController_5 : {};
      if (!manifest_5.type) {
        manifest_5.type = "14bcded7-0066-7c35-14d7-466cb3f09103-q8dzf";
      }
      if (!manifest_5.getExports && model_5) {
        manifest_5.getExports = () => ({"14bcded7-0066-7c35-14d7-466cb3f09103-q8dzf": model_5.getExports()});
      }
      if (userController_5 && userController_5.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "14bcded7-0066-7c35-14d7-466cb3f09103-q8dzf", getWidgetManifest: userController_5.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_5);
  
      const userController_6 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Metadata/editor.controller.ts');

      const model_6 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Metadata/model.ts').default;

      const manifest_6 = userController_6 ? userController_6.default || userController_6 : {};
      if (!manifest_6.type) {
        manifest_6.type = "14bcded7-0066-7c35-14d7-466cb3f09103-wzdp6";
      }
      if (!manifest_6.getExports && model_6) {
        manifest_6.getExports = () => ({"14bcded7-0066-7c35-14d7-466cb3f09103-wzdp6": model_6.getExports()});
      }
      if (userController_6 && userController_6.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "14bcded7-0066-7c35-14d7-466cb3f09103-wzdp6", getWidgetManifest: userController_6.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_6);
  
      const userController_7 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Ratings/editor.controller.ts');

      const model_7 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Ratings/model.ts').default;

      const manifest_7 = userController_7 ? userController_7.default || userController_7 : {};
      if (!manifest_7.type) {
        manifest_7.type = "14bcded7-0066-7c35-14d7-466cb3f09103-e9hqn";
      }
      if (!manifest_7.getExports && model_7) {
        manifest_7.getExports = () => ({"14bcded7-0066-7c35-14d7-466cb3f09103-e9hqn": model_7.getExports()});
      }
      if (userController_7 && userController_7.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "14bcded7-0066-7c35-14d7-466cb3f09103-e9hqn", getWidgetManifest: userController_7.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_7);
  
      const userController_8 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Share Buttons/editor.controller.ts');

      const model_8 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Share Buttons/model.ts').default;

      const manifest_8 = userController_8 ? userController_8.default || userController_8 : {};
      if (!manifest_8.type) {
        manifest_8.type = "14bcded7-0066-7c35-14d7-466cb3f09103-y3apm";
      }
      if (!manifest_8.getExports && model_8) {
        manifest_8.getExports = () => ({"14bcded7-0066-7c35-14d7-466cb3f09103-y3apm": model_8.getExports()});
      }
      if (userController_8 && userController_8.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "14bcded7-0066-7c35-14d7-466cb3f09103-y3apm", getWidgetManifest: userController_8.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_8);
  
      const userController_9 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Tags/editor.controller.ts');

      const model_9 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Tags/model.ts').default;

      const manifest_9 = userController_9 ? userController_9.default || userController_9 : {};
      if (!manifest_9.type) {
        manifest_9.type = "14bcded7-0066-7c35-14d7-466cb3f09103-gcv5t";
      }
      if (!manifest_9.getExports && model_9) {
        manifest_9.getExports = () => ({"14bcded7-0066-7c35-14d7-466cb3f09103-gcv5t": model_9.getExports()});
      }
      if (userController_9 && userController_9.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "14bcded7-0066-7c35-14d7-466cb3f09103-gcv5t", getWidgetManifest: userController_9.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_9);
  
      const userController_10 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Title/editor.controller.ts');

      const model_10 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Title/model.ts').default;

      const manifest_10 = userController_10 ? userController_10.default || userController_10 : {};
      if (!manifest_10.type) {
        manifest_10.type = "14bcded7-0066-7c35-14d7-466cb3f09103-e25z0";
      }
      if (!manifest_10.getExports && model_10) {
        manifest_10.getExports = () => ({"14bcded7-0066-7c35-14d7-466cb3f09103-e25z0": model_10.getExports()});
      }
      if (userController_10 && userController_10.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "14bcded7-0066-7c35-14d7-466cb3f09103-e25z0", getWidgetManifest: userController_10.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_10);
  
      const userController_11 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Writer/editor.controller.ts');

      const model_11 = require('/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post Writer/model.ts').default;

      const manifest_11 = userController_11 ? userController_11.default || userController_11 : {};
      if (!manifest_11.type) {
        manifest_11.type = "14bcded7-0066-7c35-14d7-466cb3f09103-ak2wd";
      }
      if (!manifest_11.getExports && model_11) {
        manifest_11.getExports = () => ({"14bcded7-0066-7c35-14d7-466cb3f09103-ak2wd": model_11.getExports()});
      }
      if (userController_11 && userController_11.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "14bcded7-0066-7c35-14d7-466cb3f09103-ak2wd", getWidgetManifest: userController_11.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_11);

      if (editorScriptEntry.getAppManifest || controllerWidgetManifests.length) {
        const customGetAppManifest = async (...params) => {
          const [{ appManifestBuilder }, editorSDK, contextParams] = params;
          const flowAPI = await createFlowAPI(editorSDK, contextParams);
          controllerWidgetManifests.forEach(({ widgetId, getWidgetManifest}) => {
            appManifestBuilder.configureWidget(widgetId, (builder) => getWidgetManifest(builder, editorSDK, flowAPI));
          });

          if (editorScriptEntry.getAppManifest) {
            return editorScriptEntry.getAppManifest(...params, flowAPI);
          }

          return appManifestBuilder.build();
        };
        builder = builder.withAppManifest(customGetAppManifest);
      }
      var result = builder.build();
      module.exports = result;
  }
  
