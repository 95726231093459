import type { MobileHints } from '@wix/document-services-types';
import type { ComponentDefinition } from '@wix/platform-editor-sdk';
import { blogAppDefId, blogCommentsAppDefId } from '../../constants/apps';
import {
  BLOG_COMMENTS_WIDGET_ID,
  RELATED_POSTS_WIDGET_ID,
} from '../../constants/widgets';

interface Params {
  applicationId: number;
  positionY?: number;
}

export function createCommentsDefinition({
  applicationId,
  positionY,
}: Params): ComponentDefinition {
  return {
    layout: {
      x: 0,
      y: positionY ?? 0,
      width: 980,
      height: 1048,
    },
    type: 'Container',
    skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
    componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
    data: {
      widgetId: BLOG_COMMENTS_WIDGET_ID,
      applicationId: `${applicationId}`,
      appDefinitionId: blogCommentsAppDefId,
      type: 'TPAWidget',
    },
    mobileHints: {
      type: 'MobileHints',
      shouldBeForceConverted: true,
      recommendedWidth: 320,
    } as MobileHints,
    style: 'tpaw0',
  };
}

export function createRelatedPostsDefinition({
  applicationId,
  positionY,
}: Params): ComponentDefinition {
  return {
    layout: {
      x: 0,
      y: positionY ?? 0,
      width: 980,
      height: 380,
    },
    type: 'Container',
    skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
    componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
    data: {
      widgetId: RELATED_POSTS_WIDGET_ID,
      applicationId: `${applicationId}`,
      appDefinitionId: blogAppDefId,
      type: 'TPAWidget',
    },
    mobileHints: {
      type: 'MobileHints',
      shouldBeForceConverted: true,
      recommendedWidth: 320,
    } as MobileHints,
    style: 'tpaw0',
  };
}
